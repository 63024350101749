import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';

export interface TokenState {
  amounts: AmountDetails;
}

const initialState: TokenState = {
  amounts: {},
};

const tokenSlice = createSlice({
  name: 'token',
  initialState,
  reducers: {
    updateAmounts: (state, action: PayloadAction<AmountDetails>) => {
      state.amounts = {
        ...state.amounts,
        ...action.payload
      };
    },
    removeToken: (state, action: PayloadAction<AmountDetails>) => {
      state.amounts = {};
    },
  }
});

// Action creators are generated for each case reducer function
export const {
  updateAmounts,
  removeToken,
} = tokenSlice.actions;

export const selectAmounts = (state: RootState): AmountDetails => state.persistStorage.token.amounts;

export default tokenSlice.reducer;
