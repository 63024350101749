import React from 'react';
import cn from 'classnames/bind';
import styles from './connectWallet.module.scss';
import { IconDark, IconLight } from 'assets/icons';
import useTheme from 'hooks/useTheme';
const cx = cn.bind(styles);

const LightDarkMode: React.FC = () => {
  const { theme, setTheme } = useTheme();
  return (
    <div className={cx('light-dark')} onClick={() => setTheme(theme === 'dark' ? 'light' : 'dark')}>
      <img className={cx('icon', 'light', theme === 'dark' ? 'active' : '')} src={IconLight} alt="light" />
      <img className={cx('icon', 'dark', theme === 'light' ? 'active' : '')} src={IconDark} alt="dark" />
    </div>
  );
};

export default LightDarkMode;
