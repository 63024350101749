import { fromBinary, toBinary } from '@cosmjs/cosmwasm-stargate';
import { StargateClient } from '@cosmjs/stargate';
import { oraichainTokens, tokenMap } from 'config/bridgeTokens';
import { OraiswapTokenTypes } from '@oraichain/oraidex-contracts-sdk';
import bech32 from 'bech32';
import { Dispatch } from '@reduxjs/toolkit';
import { network } from 'config/networks';
import { MulticallQueryClient } from '@oraichain/common-contracts-sdk';
import { useDispatch } from 'react-redux';
import { removeToken, updateAmounts } from 'redux/reducers/tokenSlice';
import { handleCheckWallet } from 'helpers';
import { chainInfos } from 'config/chainInfos';

async function loadNativeBalance(dispatch: Dispatch, address: string, tokenInfo: { chainId: string; rpc: string }) {
  if (!address) return;
  const client = await StargateClient.connect(tokenInfo.rpc);
  const amountAll = await client.getAllBalances(address);

  let amountDetails: AmountDetails = {};

  // reset native balances
  oraichainTokens
    .filter((t) => t.chainId === tokenInfo.chainId && !t.contractAddress)
    .forEach((t) => {
      amountDetails[t.denom] = '0';
    });

  Object.assign(
    amountDetails,
    Object.fromEntries(amountAll.filter((coin) => tokenMap[coin.denom]).map((coin) => [coin.denom, coin.amount]))
  );

  dispatch(updateAmounts(amountDetails));
}

async function loadTokens(dispatch: Dispatch, oraiAddress: string) {
  if (!oraiAddress) {
    dispatch(removeToken());
    return;
  }
  await Promise.all([loadCw20Balance(dispatch, oraiAddress), loadTokensCosmos(dispatch, oraiAddress)].filter(Boolean));
}

async function loadTokensCosmos(dispatch: Dispatch, address: string) {
  await handleCheckWallet();
  const { words, prefix } = bech32.decode(address);
  const cosmosInfos = chainInfos.filter((chainInfo) => chainInfo.bip44.coinType === 118);
  for (const chainInfo of cosmosInfos) {
    const networkPrefix = chainInfo.bech32Config.bech32PrefixAccAddr;
    const cosmosAddress = networkPrefix === prefix ? address : bech32.encode(networkPrefix, words);
    loadNativeBalance(dispatch, cosmosAddress, chainInfo);
  }
}

async function loadCw20Balance(dispatch: Dispatch, address: string) {
  if (!address) return;
  // get all cw20 token contract
  const cw20Tokens = oraichainTokens.filter((t) => t.contractAddress);

  const data = toBinary({
    balance: { address }
  });
  const multicall = new MulticallQueryClient(window.client, network.multicall);
  const res = await multicall.tryAggregate({
    queries: cw20Tokens.map((t) => ({
      address: t.contractAddress,
      data
    }))
  });

  const amountDetails = Object.fromEntries(
    cw20Tokens.map((t, ind) => {
      if (!res.return_data[ind].success) {
        return [t.denom, 0];
      }
      const balanceRes = fromBinary(res.return_data[ind].data) as OraiswapTokenTypes.BalanceResponse;
      const amount = balanceRes.balance;
      return [t.denom, amount];
    })
  );
  dispatch(updateAmounts(amountDetails));
}

export default function useLoadTokens(): (oraiAddress: string) => Promise<void> {
  const dispatch = useDispatch();
  return loadTokens.bind(null, dispatch);
}
