import React, { Suspense, memo } from 'react';
import { Routes, Route } from 'react-router-dom';
import { ROUTES } from './constant';

const Trading = React.lazy(() => import('pages/Trading'));
const Ranking = React.lazy(() => import('pages/Ranking'));

const OraiRoutes: React.FC = () => {
  return (
    <Suspense
      fallback={
        <div
          data-testid="route-display"
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: 'calc(100vh - 300px)'
          }}
        >
          Loading...
        </div>
      }
    >
      <Routes>
        <Route path="" element={<Trading />} />
        <Route path=":pair" element={<Trading />} />
        <Route path=":pair/:mode" element={<Trading />} />
        <Route path={ROUTES.TRADE} element={<Trading />} />
        <Route path={ROUTES.RANKING} element={<Ranking />} />
      </Routes>
    </Suspense>
  );
};

export default memo(OraiRoutes);
