import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';

export interface ActionState {
  openHeaderMenu: boolean;
}

const initialState: ActionState = {
  openHeaderMenu: false
};

const actionsSlice = createSlice({
  name: 'actions',
  initialState,
  reducers: {
    updateOpenMenuHeader: (state, action: PayloadAction<boolean>) => {
      state.openHeaderMenu = action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const { updateOpenMenuHeader } = actionsSlice.actions;

export const selectOpenMenuState = (state: RootState): boolean => state.actions.openHeaderMenu;

export default actionsSlice.reducer;
