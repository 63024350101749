import { networkInfo } from 'config/constants';
import { getAddressByEIP191 } from 'helpers';
import useConfigReducer from 'hooks/useConfigReducer';
import { getWalletByNetworkCosmosFromStorage } from 'libs/utils';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useMedia } from 'react-use';
import useLoadTokens from './useLoadTokens';
import { WALLET_ENUM } from 'components/ConnectWallet/constants';

export function useEagerConnect() {
  const loadTokenAmounts = useLoadTokens();
  const [, setOraiAddress] = useConfigReducer('address');
  const { pathname } = useLocation();
  const isMobile = useMedia('(max-width: 991px)');

  const connect = async () => {
    try {
      const walletType = getWalletByNetworkCosmosFromStorage();

      if (walletType === WALLET_ENUM.EIP191) {
        const isSwitchEIP = true;
        const oraiAddress = await getAddressByEIP191(isSwitchEIP);

        setOraiAddress(oraiAddress);
        loadTokenAmounts(oraiAddress);
      }
    } catch (error) {
      setOraiAddress(undefined);
      console.log({ errorConnectMetmask: error });
      throw new Error(error?.message ?? JSON.stringify(error));
    }
  };

  useEffect(() => {
    // just auto connect metamask in mobile
    isMobile && connect();
  }, [networkInfo.chainId, pathname, isMobile]);

  return connect;
}

export function useInactiveConnect() {
  const connect = useEagerConnect();

  useEffect(() => {
    const { ethereum } = window;

    if (ethereum && ethereum.on) {
      ethereum.on('accountsChanged', connect);
      return () => {
        if (ethereum.removeListener) {
          ethereum.removeListener('accountsChanged', connect);
        }
      };
    }
  }, []);
  return connect;
}
