import React, { FC, useState, ComponentType } from 'react';
import ReactModal from 'react-modal';
import styles from './Modal.module.scss';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ReactComponent as CloseIconDark } from 'assets/icons/close-dark.svg';
import useTheme from 'hooks/useTheme';
import RenderWhen from 'components/RenderWhen';

if (process.env.NODE_ENV !== 'test') ReactModal.setAppElement('#root');
const ModalSafeForReact18 = ReactModal as ComponentType<ReactModal['props']>;

const Modal: FC<
  Modal & { children: React.ReactElement | React.ReactElement[] | React.ReactNode | React.ReactNode[] }
> = ({ className, isOpen, close, children, isCloseBtn = false }) => {
  const { theme } = useTheme();

  return (
    <ModalSafeForReact18
      className={`${styles.modal} ${className || ''}`}
      overlayClassName={`${styles.overlay} ${className || ''}`}
      isOpen={isOpen}
      onRequestClose={close}
    >
      <RenderWhen>
        <RenderWhen.If isTrue={isCloseBtn}>
          <div className={styles.close}>
            <span onClick={close}>
              {theme === 'dark' ? <CloseIcon width={20} height={20} /> : <CloseIconDark width={20} height={20} />}
            </span>
          </div>
        </RenderWhen.If>
      </RenderWhen>
      {children}
    </ModalSafeForReact18>
  );
};

export default Modal;

/* modal */
export const useModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  return { isOpen, open: () => setIsOpen(true), close: () => setIsOpen(false) };
};
