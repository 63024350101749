import { FC, FunctionComponent, SVGProps } from 'react';
import styles from './LoginWidget.module.scss';
import cn from 'classnames/bind';
import CenterEllipsis from 'components/CenterEllipsis';
import { ReactComponent as Logout } from 'assets/icons/logout.svg';
import useTheme from '../../hooks/useTheme';
import CopyCustom from 'components/CopyCustom';
import RenderWhen from 'components/RenderWhen';
import { getActiveStatusWallet } from 'helpers';
import { CosmosWalletType } from './constants';
import useWalletReducer from 'hooks/useWalletReducer';
import { isMobile } from 'libs/utils';

const cx = cn.bind(styles);

export const LoginWidget: FC<{
  logo: FunctionComponent<SVGProps<SVGSVGElement> & { title?: string }>;
  text: string;
  address: string | null;
  connect: () => void;
  disconnect: () => void;
  registryName: CosmosWalletType | null;
}> = ({ logo, text, address, connect, disconnect, registryName }) => {
  const { theme } = useTheme();

  const [walletByNetworks, setWalletByNetworks] = useWalletReducer('walletsByNetwork');
  const currentWallet = walletByNetworks.cosmos;
  const checkWalletStatus = getActiveStatusWallet();
  const inactive = !checkWalletStatus[registryName];

  const WalletLogo = logo;

  const canHandleAction = !isMobile() && !inactive;

  return (
    <div
      className={cx('item', theme, { inactive })}
      onClick={!canHandleAction ? () => {} : address ? disconnect : connect}
    >
      <WalletLogo />
      <div className={cx('grow')}>
        <RenderWhen>
          <RenderWhen.If isTrue={!!address}>
            <div className={cx('network-title')}>{text}</div>
            <div className={cx('des')}>
              <CenterEllipsis size={6} text={address} />
              <CopyCustom value={address} />
            </div>
          </RenderWhen.If>
          <RenderWhen.If isTrue={!address && currentWallet !== registryName}>
            <div className={cx('network-title')}>{text}</div>
            <div className={cx('des')}>Connect using browser wallet</div>
          </RenderWhen.If>
        </RenderWhen>
      </div>
      <RenderWhen>
        <RenderWhen.If isTrue={!isMobile() && !!address && !!disconnect && currentWallet === registryName}>
          <div>
            <Logout />
          </div>
        </RenderWhen.If>
      </RenderWhen>
    </div>
  );
};

export default LoginWidget;
