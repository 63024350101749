import { WALLET_ENUM, cosmosWalletProvider } from 'components/ConnectWallet/constants';
import { isMobile } from 'libs/utils';
import { useEffect } from 'react';
import { PERSIST_VER } from 'redux/constants';
import { persistor } from 'redux/store';
import useConfigReducer from './useConfigReducer';
import useWalletReducer from './useWalletReducer';

export const useClearStorage = () => {
  const [persistVersion, setPersistVersion] = useConfigReducer('persistVersion');
  const [walletByNetworks, setWalletByNetworks] = useWalletReducer('walletsByNetwork');
  const { networkType } = cosmosWalletProvider;

  useEffect(() => {
    const isClearPersistStorage = persistVersion === undefined || persistVersion !== PERSIST_VER;
    const clearPersistStorage = () => {
      persistor.pause();
      persistor.flush().then(() => {
        return persistor.purge();
      });

      setPersistVersion(PERSIST_VER);
    };

    if (isMobile()) {
      setWalletByNetworks({
        ...walletByNetworks,
        [networkType]: WALLET_ENUM.OWALLET
      });
    }

    if (isClearPersistStorage) clearPersistStorage();
  }, []);

  return { persistVersion, setPersistVersion };
};
