import { OrderStatus, TradeStatus } from 'redux/reducers/type';

export enum OrderTypeKeys {
  LIMIT = 'limit',
  MARKET_PRICE = 'market'
}

export enum OrderTypeTexts {
  LIMIT = 'Limit',
  MARKET_PRICE = 'Market Price'
}

export enum OrderSideKeys {
  ALL = '1',
  BUY = '2',
  SELL = '3'
}

export enum OrderSideTexts {
  ALL = 'All',
  BUY = 'Buy',
  SELL = 'Sell'
}

export const ORDER_TYPES: { key: OrderTypeKeys; title: OrderTypeTexts }[] = [
  {
    key: OrderTypeKeys.LIMIT,
    title: OrderTypeTexts.LIMIT
  },
  {
    key: OrderTypeKeys.MARKET_PRICE,
    title: OrderTypeTexts.MARKET_PRICE
  }
];

export const ORDER_STATUS: { key: string; title: string }[] = [
  {
    key: `${TradeStatus.FULFILLED},${TradeStatus.CANCEL},${TradeStatus.PARTIAL_FILLED}`,
    title: 'All'
  },
  {
    key: `${TradeStatus.FULFILLED},${TradeStatus.PARTIAL_FILLED}`,
    title: 'Complete'
  },
  {
    key: `${TradeStatus.CANCEL}`,
    title: 'Cancelled'
  }
];

export const SIDE_TYPES: { key: OrderSideKeys; title: OrderSideTexts }[] = [
  {
    key: OrderSideKeys.ALL,
    title: OrderSideTexts.ALL
  },
  {
    key: OrderSideKeys.BUY,
    title: OrderSideTexts.BUY
  },
  {
    key: OrderSideKeys.SELL,
    title: OrderSideTexts.SELL
  }
];

export const ORDER_STATUS_MAPS = {
  2: ORDER_STATUS[1].title,
  10: ORDER_STATUS[2].title
};

export const ORDER_STATUS_CODE_MAPS = {
  [OrderStatus.OPEN]: 1,
  [OrderStatus.FUL_FILLED]: 2,
  [OrderStatus.FILLING]: 3,
  [OrderStatus.CLOSE]: 4,
  [OrderStatus.CANCELED]: 10
};
