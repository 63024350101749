import React, { useState } from 'react';
import cn from 'classnames/bind';
import styles from './index.module.scss';
import { IconCopy } from 'assets/icons';
import { onCopyClipboard } from 'libs/utils';
import Tooltip from 'components/Tooltip';
const cx = cn.bind(styles);

const CopyCustom: React.FC<{
  value: string;
}> = ({ value }) => {
  const [textTooltip, setTextTooltip] = useState('Copy');
  const [visible, setVisible] = useState(false);
  const handleCopy = (event) => {
    event.stopPropagation();
    onCopyClipboard(value);
    setTextTooltip('Copied!');
  };

  return (
    <Tooltip visible={visible} content={textTooltip}>
      <div
        className={cx('copy-custom')}
        onMouseOut={() => {
          setVisible(false);
          setTextTooltip('Copy');
        }}
        onMouseOver={() => setVisible(true)}
        onClick={handleCopy}
      >
        <img src={IconCopy} alt="copy" />
      </div>
    </Tooltip>
  );
};

export default CopyCustom;
