import React, { FC } from 'react';
import Tippy, { TippyProps } from '@tippyjs/react';
import classNames from 'classnames';

import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light-border.css';
import styles from './Tooltip.module.scss';
import RenderWhen from 'components/RenderWhen';

export const DefaultTippyProps: TippyProps = {
  animation: false,
  interactive: true,
  appendTo: document.body
};

const TooltipTippyProps: TippyProps = {
  ...DefaultTippyProps,
  placement: 'top',
  theme: 'dark-border',
  className: styles.tooltip
};

interface Props extends Omit<TippyProps, 'children'> {
  onClick?: () => void;
  children?: React.ReactNode | React.ReactNode[] | React.ReactElement | React.ReactElement[];
}

const Tooltip: FC<Props> = ({ className, onClick, children, ...props }) => {
  const button = (
    <span className={classNames(styles.button, className)} onClick={onClick}>
      {children}
    </span>
  );

  return (
    <RenderWhen>
      <RenderWhen.If isTrue={!!props.content}>
        <Tippy {...TooltipTippyProps} {...props}>
          {button}
        </Tippy>
      </RenderWhen.If>
      <RenderWhen.If isTrue={!props.content}>{button}</RenderWhen.If>
    </RenderWhen>
  );
};

export const TooltipIcon: FC<Props> = ({ children, ...props }) => (
  <div className={styles.flex}>
    <div className={styles.icon}>
      <Tooltip {...props}>
        {children}
      </Tooltip>
    </div>
  </div>
);

export default Tooltip;
