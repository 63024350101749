import React, { memo, useState } from 'react';
import cn from 'classnames/bind';
import styles from './connectWallet.module.scss';
import Button from 'components/Button';
import useConfigReducer from 'hooks/useConfigReducer';
import RequireAuthButton from 'components/ConnectWallet/RequireAuthButton';
import TokenBalance from 'components/TokenBalance';
import { useSelector } from 'react-redux';
import { selectAmounts } from 'redux/reducers/tokenSlice';
import useTheme from 'hooks/useTheme';
import useWindowSize from 'hooks/useWindowSize';
import { selectCurrentToken } from 'redux/reducers/tradingSlice';
import RenderWhen from 'components/RenderWhen';
import { getCoinDetail } from './helpers';
import { cosmosWalletProvider, cosmosWallets } from 'components/ConnectWallet/constants';
import useWalletReducer from 'hooks/useWalletReducer';
import { ReactComponent as DownArrowIcon } from 'assets/icons/arrow_down.svg';
import Drawer from 'components/Drawer';
import CenterEllipsis from 'components/CenterEllipsis';
import CopyCustom from 'components/CopyCustom';
import LightDarkMode from './LightDarkMode';

import { ReactComponent as Logout } from 'assets/icons/logout.svg';
const cx = cn.bind(styles);

const TokenInfo: React.FC<{
  name: string;
  balance: string;
  decimals: number;
  logo: {
    dark: any;
    light: any;
  };
}> = ({ name, balance, decimals, logo }) => {
  const { theme } = useTheme();
  return (
    <div className={cx('token-info')}>
      <RenderWhen>
        <RenderWhen.If isTrue={theme === 'dark'}>
          <img src={logo.light} alt="logo" className={cx('token-avatar')} />
        </RenderWhen.If>
        <RenderWhen.If isTrue={theme !== 'dark'}>
          <img src={logo.dark} alt="logo" className={cx('token-avatar')} />
        </RenderWhen.If>
      </RenderWhen>
      <div className={cx('token-info-balance')}>
        <span className={cx('token-address')}>{name}</span>
        {
          <TokenBalance
            balance={{
              amount: balance,
              decimals,
              denom: name
            }}
            className={cx('token-balance')}
            decimalScale={3}
          />
        }
      </div>
    </div>
  );
};

const ConnectWallet: React.FC<{
  hasButtonConnect?: boolean;
}> = ({ hasButtonConnect = true }) => {
  const { theme } = useTheme();
  const { isMobileMode } = useWindowSize();
  const [address, setAddress] = useConfigReducer('address');
  const [visible, setVisible] = useState(false);
  const amounts: AmountDetails = useSelector(selectAmounts);
  const [oraiAddress, setOraiAddress] = useConfigReducer('address');
  const currentPair = useSelector(selectCurrentToken);

  const baseCoin = getCoinDetail(currentPair?.from, amounts);
  const quoteCoin = getCoinDetail(currentPair?.to, amounts);

  const [walletByNetworks, setWalletByNetworks] = useWalletReducer('walletsByNetwork');
  const { networkType } = cosmosWalletProvider;
  const currentWallet = walletByNetworks.cosmos;

  const currentWalletConnect = cosmosWallets.find((w) => currentWallet && w.registryName === currentWallet);

  const handleDisconnectWallet = async () => {
    setWalletByNetworks({
      ...walletByNetworks,
      [networkType]: null
    });

    setOraiAddress(undefined);

    if (walletByNetworks.cosmos === 'eip191') {
      localStorage.removeItem('eip191-account');
    }

    localStorage.removeItem('typeWallet');
  };

  const AccountDetail = () => {
    return (
      <div className={styles.account_detail}>
        {!isMobileMode && (
          <div className={styles.header}>
            <span>My Wallets</span>
            {/* <Button typeButton="outline">
            <RequireAuthButton address={address} setAddress={setAddress}>
              Change Account
            </RequireAuthButton>
          </Button> */}
          </div>
        )}
        <div className={styles.divider}></div>
        <div className={styles.content}>
          <div className={styles.theme}>
            <span>Theme</span>
            <LightDarkMode />
          </div>

          {currentWalletConnect && !isMobileMode && (
            <div className={styles.detail}>
              <div className={styles.detailWallet}>
                <div className={styles.icon}>{currentWalletConnect && <currentWalletConnect.icon />}</div>

                <div className={styles.desc}>
                  <div className={styles.network_title}>{currentWalletConnect.name || ''}</div>
                  <div className={styles.address}>
                    <CenterEllipsis size={6} text={address} />
                    <CopyCustom value={address} />
                  </div>
                </div>
                {!isMobileMode && (
                  <div
                    className={styles.logout}
                    onClick={() => {
                      handleDisconnectWallet();
                      setVisible(false);
                    }}
                  >
                    <Logout />
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className={cx('connect-wallet', theme, isMobileMode ? 'mobile' : '')}>
      <RenderWhen>
        <RenderWhen.If isTrue={!!address && !!currentWalletConnect}>
          <div className={styles.currentConnect} onClick={() => setVisible(!visible)}>
            <div className={styles.icon}>{currentWalletConnect && <currentWalletConnect.icon />}</div>
            <div className={styles.info}>
              <div className={styles.network_title}>My Wallets</div>
              <CenterEllipsis size={6} text={address} />
            </div>
            <div className={styles.arrow}>
              <DownArrowIcon />
            </div>
          </div>

          {isMobileMode ? (
            visible && <AccountDetail />
          ) : (
            <Drawer
              showClose={false}
              visible={visible}
              contentWidth={290}
              position={'right'}
              close={() => setVisible(false)}
            >
              <AccountDetail />
            </Drawer>
          )}

          {/* <RequireAuthButton address={address} setAddress={setAddress}>
            <div className={cx('token-box')}>
              <TokenInfo
                name={baseCoin.name}
                balance={baseCoin.balance}
                decimals={baseCoin.decimals}
                logo={baseCoin.logo}
              />
              <TokenInfo
                name={quoteCoin.name}
                balance={quoteCoin.balance}
                decimals={quoteCoin.decimals}
                logo={quoteCoin.logo}
              />
            </div>
          </RequireAuthButton> */}
        </RenderWhen.If>

        <RenderWhen.If isTrue={!address && hasButtonConnect}>
          <RequireAuthButton address={address} setAddress={setAddress}>
            <Button className={cx('btn-connect')} typeButton="outline">
              <span style={{ position: 'relative', zIndex: '1' }}>Connect Wallet</span>
            </Button>
          </RequireAuthButton>
        </RenderWhen.If>
      </RenderWhen>
    </div>
  );
};

export default memo(ConnectWallet);
