import { createSlice, current } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';
import {
  PairToken,
  RecentlyTraded,
  TradingState,
  OrderDetailFromContract,
  OrderDetailFromBE,
  DirectionTrade
} from './type';
import { searchTokenWithSymbol } from 'helpers/orderbook';

const initialState: TradingState = {
  listToken: [],
  listTokenFilter: [],
  currentToken: null,
  dataRecentlyTraded: [],
  dataBuyOrderbook: [],
  dataSellOrderbook: [],
  originalBuyOrders: [],
  originalSellOrders: [],
  valueChartMove: null,
  currentPrice: '',
  loadingList: false
};

const tradingSlice = createSlice({
  name: 'trading',
  initialState,
  reducers: {
    searchToken: (state, action: PayloadAction<string>) => {
      state.listTokenFilter = searchTokenWithSymbol(action.payload, current(state).listToken);
    },
    setLoadingList: (state, action: PayloadAction<boolean>) => {
      state.loadingList = action.payload;
    },
    setListToken: (state, action: PayloadAction<PairToken[]>) => {
      state.listToken = action.payload;
    },
    setListTokenFilterInitial: (state, action: PayloadAction<PairToken[]>) => {
      state.listTokenFilter = action.payload;
    },
    setCurrentToken: (state, action: PayloadAction<PairToken>) => {
      state.currentToken = action.payload;
    },
    addDataRecentlyTraded: (state, action: PayloadAction<RecentlyTraded[]>) => {
      state.dataRecentlyTraded = action.payload;
    },
    addSellOrderbookTraded: (state, action: PayloadAction<OrderDetailFromBE[]>) => {
      state.dataSellOrderbook = action.payload;
    },
    addBuyOrderbookTraded: (state, action: PayloadAction<OrderDetailFromBE[]>) => {
      state.dataBuyOrderbook = action.payload;
    },
    addOriginalSellOrderbookTraded: (state, action: PayloadAction<any[]>) => {
      state.originalSellOrders = action.payload;
    },
    addOriginalBuyOrderbookTraded: (state, action: PayloadAction<any[]>) => {
      state.originalBuyOrders = action.payload;
    },
    updateSellOrderbookTraded: (state, action: PayloadAction<OrderDetailFromBE[]>) => {
      // const newList = mergeOrderArrays(state.dataSellOrderbook, action.payload || [], DirectionTrade.SELL);
      state.dataSellOrderbook = action.payload;
    },
    updateBuyOrderbookTraded: (state, action: PayloadAction<OrderDetailFromBE[]>) => {
      // const newList = mergeOrderArrays(state.dataBuyOrderbook, action.payload || [], DirectionTrade.BUY);
      state.dataBuyOrderbook = action.payload;
    },
    setCurrentPrice: (state, action: PayloadAction<string>) => {
      state.currentPrice = action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const {
  searchToken,
  setCurrentToken,
  setListToken,
  setListTokenFilterInitial,
  addDataRecentlyTraded,
  addSellOrderbookTraded,
  addBuyOrderbookTraded,
  addOriginalSellOrderbookTraded,
  addOriginalBuyOrderbookTraded,
  setCurrentPrice,
  updateBuyOrderbookTraded,
  updateSellOrderbookTraded,
  setLoadingList
} = tradingSlice.actions;

export const selectTokenFilter = (state: RootState): PairToken[] => state.trading.listTokenFilter;
export const selectCurrentToken = (state: RootState): PairToken => state.trading.currentToken;
export const selectDataRecentlyTraded = (state: RootState): RecentlyTraded[] => state.trading.dataRecentlyTraded;
export const selectOriginalSells = (state: RootState): any[] => state.trading.originalSellOrders;
export const selectOriginalBuys = (state: RootState): any[] => state.trading.originalBuyOrders;
export const selectSellDataOrderbook = (state: RootState): OrderDetailFromBE[] => state.trading.dataSellOrderbook;
export const selectBuyDataOrderbook = (state: RootState): OrderDetailFromBE[] => state.trading.dataBuyOrderbook;
export const selectCurrentPrice = (state: RootState): string => state.trading.currentPrice;
export const selectListToken = (state: RootState): PairToken[] => state.trading.listToken;
export const selectLoadingList = (state: RootState): boolean => state.trading.loadingList;

export default tradingSlice.reducer;
