import PRODUCT_ORDERBOOK from './PRODUCT_ORDERBOOK';
import STAGING_ORDERBOOK from './STAGING_ORDERBOOK';

const REACT_APP_ENV = process.env.REACT_APP_ENV;
const WRAP_ENV = {
  PRODUCT_ORDERBOOK,
  STAGING_ORDERBOOK
};

export const ROOT_ENV = WRAP_ENV[REACT_APP_ENV || 'PRODUCT_ORDERBOOK'];
