import { updateConfig } from 'redux/reducers/configSlice';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'redux/store';
import { ConfigState } from 'redux/reducers/type';

// help typescript realize return type from key of ConfigState
export default function useConfigReducer<StateKey extends keyof ConfigState>(
  key: StateKey
): readonly [ConfigState[StateKey], (value: ConfigState[StateKey]) => void] {
  const value = useSelector((state: RootState) => state.persistStorage.config[key]);
  const dispatch = useDispatch();
  const setValue = (newValue: ConfigState[StateKey]) => {
    dispatch(updateConfig(key, newValue));
  };
  return [value, setValue];
}
