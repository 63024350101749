import { Asset, AssetInfo } from '@oraichain/oraidex-contracts-sdk';
import { TokenInfoResponse } from '@oraichain/oraidex-contracts-sdk/build/OraiswapToken.types';
import { oraichainTokens, TokenItemType, tokenMap } from 'config/bridgeTokens';
import { ORAI_SCAN } from 'config/constants';
import { CoinGeckoPrices } from 'hooks/useCoingecko';
import { TokenInfo } from 'types/token';
import { COSMOS_CHAIN_ID_COMMON, CosmosChainId, NetworkChainId } from '@oraichain/oraidex-common';
import { network } from 'config/networks';
import { CosmosWalletType } from 'components/ConnectWallet/constants';
import { GasPrice } from '@cosmjs/stargate';

const truncDecimals = 6;
const atomic = 10 ** truncDecimals;

export const validateNumber = (amount: number | string): number => {
  if (typeof amount === 'string') return validateNumber(Number(amount));
  if (Number.isNaN(amount) || !Number.isFinite(amount)) return 0;
  return amount;
};

// decimals always >= 6
export const toAmount = (amount: number | string, decimals = 6): bigint => {
  const validatedAmount = validateNumber(amount);
  return BigInt(Math.trunc(validatedAmount * atomic)) * BigInt(10 ** (decimals - truncDecimals));
};

export const toAmountString = (amount: number | string, decimals = 6): string => {
  return toAmount(amount, decimals).toString();
};

export const toAmountDisplay = (amount: number | string, decimals = 6): string => {
  return (Number(amount) / Number(10 ** decimals)).toFixed(6).toString();
};

export const toDecimal = (numerator: bigint, denominator: bigint): number => {
  if (denominator === BigInt(0)) return 0;
  return toDisplay((numerator * BigInt(atomic)) / denominator, truncDecimals);
};

export const toDisplay = (amount: string | bigint, sourceDecimals = 6, desDecimals = 6): number => {
  if (!amount) return 0;
  if (typeof amount === 'string' && amount.indexOf('.') !== -1) amount = amount.split('.')[0];
  try {
    // guarding conditions to prevent crashing
    const validatedAmount = typeof amount === 'string' ? BigInt(amount || '0') : amount;
    const displayDecimals = Math.min(truncDecimals, desDecimals);
    const returnAmount = validatedAmount / BigInt(10 ** (sourceDecimals - displayDecimals));
    // save calculation by using cached atomic
    return Number(returnAmount) / (displayDecimals === truncDecimals ? atomic : 10 ** displayDecimals);
  } catch {
    return 0;
  }
};

export const getUsd = (amount: string | bigint, tokenInfo: TokenItemType, prices: CoinGeckoPrices<string>): number => {
  return toDisplay(amount, tokenInfo.decimals) * (prices[tokenInfo.coinGeckoId] ?? 0);
};

export const getUsdWithToken = ({
  amount,
  coinGeckoId,
  prices
}: {
  amount: number;
  coinGeckoId: string;
  prices: CoinGeckoPrices<string>;
}): number => {
  return amount * (prices[coinGeckoId] ?? 0);
};

export const getOraiWithToken = ({
  amount,
  coinGeckoId,
  prices
}: {
  amount: number;
  coinGeckoId: string;
  prices: CoinGeckoPrices<string>;
}): number => {
  return (amount * (prices[coinGeckoId] ?? 0)) / prices['oraichain-token'];
};

export const getTotalUsd = (amounts: AmountDetails, prices: CoinGeckoPrices<string>): number => {
  let usd = 0;
  for (const denom in amounts) {
    const tokenInfo = tokenMap[denom];
    if (!tokenInfo) continue;
    const amount = toDisplay(amounts[denom], tokenInfo.decimals);
    usd += amount * (prices[tokenInfo.coinGeckoId] ?? 0);
  }
  return usd;
};

export const toSumDisplay = (amounts: AmountDetails): number => {
  // get all native balances that are from oraibridge (ibc/...)
  let amount = 0;

  for (const denom in amounts) {
    // update later
    const balance = amounts[denom];
    if (!balance) continue;
    amount += toDisplay(balance, tokenMap[denom].decimals);
  }
  return amount;
};

export const reduceString = (str: string, from: number, end: number) => {
  return str ? str.substring(0, from) + '...' + str.substring(str.length - end) : '-';
};

export const parseBep20Erc20Name = (name: string) => {
  return name.replace(/(BEP20|ERC20)\s+/, '');
};

export const toTokenInfo = (token: TokenItemType, info?: TokenInfoResponse): TokenInfo => {
  const data = (info as any)?.token_info_response ?? info;
  return {
    ...token,
    symbol: token.name,
    verified: !token.contractAddress,
    ...data
  };
};

export const toAssetInfo = (token: TokenInfo): AssetInfo => {
  return token.contractAddress
    ? {
        token: {
          contract_addr: token.contractAddress
        }
      }
    : { native_token: { denom: token.denom } };
};

export const buildMultipleMessages = (mainMsg?: any, ...preMessages: any[]) => {
  let messages: any[] = mainMsg ? [mainMsg] : [];
  messages.unshift(...preMessages.flat(1));
  messages = messages.map((msg) => ({
    contractAddress: msg.contract,
    handleMsg: msg.msg.toString(),
    handleOptions: { funds: msg.sent_funds }
  }));
  return messages;
};

export const delay = (timeout: number) => new Promise((resolve) => setTimeout(resolve, timeout));

export const formateNumberDecimals = (price, decimals = 2) => {
  return new Intl.NumberFormat('en-US', {
    currency: 'USD',
    maximumFractionDigits: decimals
  }).format(price);
};

export const detectBestDecimalsDisplay = (price, minDecimal = 2, minPrice = 1, maxDecimal) => {
  if (price && price > minPrice) return minDecimal;
  let decimals = minDecimal;
  if (price !== undefined) {
    // Find out the number of leading floating zeros via regex
    const priceSplit = price?.toString().split('.');
    if (priceSplit?.length === 2 && priceSplit[0] === '0') {
      const leadingZeros = priceSplit[1].match(/^0+/);
      decimals += leadingZeros ? leadingZeros[0]?.length + 1 : 1;
    }
  }
  if (maxDecimal && decimals > maxDecimal) decimals = maxDecimal;
  return decimals;
};

interface FormatNumberDecimal {
  price: number;
  maxDecimal?: number;
  unit?: string;
  minDecimal?: number;
  minPrice?: number;
  unitPosition?: 'prefix' | 'suffix';
}

export const formateNumberDecimalsAuto = ({
  price,
  maxDecimal,
  unit,
  minDecimal,
  minPrice,
  unitPosition
}: FormatNumberDecimal) => {
  minDecimal = minDecimal ? minDecimal : 2;
  minPrice = minPrice ? minPrice : 1;
  unit = unit ? unit : '';
  const priceFormat = formateNumberDecimals(price, detectBestDecimalsDisplay(price, minDecimal, minPrice, maxDecimal));
  const res = unitPosition === 'prefix' ? unit + priceFormat : priceFormat + unit;
  return res;
};

export const isValueNumber = (value) => {
  if (!value) {
    return false;
  } else if (isNaN(value)) {
    return false;
  } else {
    return true;
  }
};

export const processWsResponseMsg = (message: any): string => {
  if (message === null || message.result === null) {
    return null;
  }
  const { result } = message;
  if (
    result && // 👈 null and undefined check
    (Object.keys(result).length !== 0 || result.constructor !== Object)
  ) {
    if (!result.events) return null;
    const events = result.events;
    console.log('events: ', events);
    // TODO: process multiple tokens at once if there are multiple recvpacket messages
    const packets = events['recv_packet.packet_data'];
    if (!packets) return null;
    let tokens = '';
    for (let packetRaw of packets) {
      const packet = JSON.parse(packetRaw);
      // we look for the true denom information with decimals to process
      // format: {"amount":"100000000000000","denom":"oraib0xA325Ad6D9c92B55A3Fc5aD7e412B1518F96441C0","receiver":"orai...","sender":"oraib..."}
      const receivedToken = oraichainTokens.find((token) => token.denom === packet.denom);
      const displayAmount = toDisplay(packet.amount, receivedToken.decimals);
      tokens = tokens.concat(`${displayAmount} ${receivedToken.name}, `);
    }
    return tokens.substring(0, tokens.length - 2); // remove , due to concat
  }
  return null;
};

export const formatCash = (n: number) => {
  if (n < 1e3) return n;
  if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(2) + 'K';
  if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(2) + 'M';
  if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(2) + 'B';
  if (n >= 1e12) return +(n / 1e12).toFixed(1) + 'T';
};

export const onCopyClipboard = (value: string) => navigator.clipboard.writeText(value);

export const assetInfo = (denom: string, native = true): AssetInfo => {
  return native ? { native_token: { denom } } : { token: { contract_addr: denom } };
};

export const getUrlScanWithHash = (hash: string) => ORAI_SCAN + '/txs/' + hash;

export const asset = (amount: string, denom: string, native?: boolean): Asset => {
  return {
    info: assetInfo(denom, native),
    amount
  };
};

export function isObjEmpty(obj) {
  return Object.keys(obj).length === 0;
}

export function capitalizeString(value: string) {
  const str = value.toLowerCase().split(' ');
  for (let i = 0; i < str.length; i++) {
    str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
  }
  return str.join(' ');
}

export function roundWithDecimalPlaces(num: number, decimal: number = 6): number {
  return Number(num.toFixed(decimal ?? 6));
}

export function roundFloorWithDecimal(num: number, decimal: number = 3): number {
  return Math.floor(num * Math.pow(10, decimal)) / Math.pow(10, decimal);
}

export function toFixedWithDecimal(num: number, decimal?: number): string {
  return num.toFixed(decimal ?? 3);
}

export function isAndroid() {
  return /android/i.test(navigator.userAgent);
}

export function isMobile() {
  // only support ios and android os
  return /iphone|ipad|ipod|android/i.test(navigator.userAgent);
}

export const parseParams = (params) => {
  let options = '';

  for (const [key, value] of Object.entries(params)) {
    if (Array.isArray(value)) {
      for (const element of value) {
        options += `${key}=${element}&`;
      }
    } else {
      options += `${key}=${value}&`;
    }
  }

  return options.slice(0, -1);
};

export const getSubAmountDetails = (amounts: AmountDetails, tokenInfo: TokenItemType): AmountDetails => {
  if (!tokenInfo.evmDenoms) return {};
  return Object.fromEntries(
    tokenInfo.evmDenoms.map((denom) => {
      return [denom, amounts[denom]];
    })
  );
};

export const initClient = async () => {
  try {
    // suggest our chain
    const arrChainIds = [
      network.chainId,
      COSMOS_CHAIN_ID_COMMON.ORAIBRIDGE_CHAIN_ID,
      COSMOS_CHAIN_ID_COMMON.INJECTVE_CHAIN_ID
    ] as NetworkChainId[];
    for (const chainId of arrChainIds) {
      await window.Keplr.suggestChain(chainId);
    }
    const { client } =
      (await window.Keplr.getCosmWasmClient(
        { chainId: network.chainId as CosmosChainId, rpc: network.rpc },
        {
          gasPrice: GasPrice.fromString(`${network.fee.gasPrice}${network.denom}`)
        }
      )) ||
      window ||
      {};
    window.client = client;
  } catch (ex) {
    console.log({ errorInitClient: ex });
    throw new Error(ex?.message ?? 'Error when suggestChain');
  }
};

// TECH DEBT: need to update WalletTypeCosmos add type eip191 to oraidex-common
export const getWalletByNetworkCosmosFromStorage = (key = 'persist:root'): CosmosWalletType => {
  try {
    if (isMobile()) return 'owallet';

    const result = localStorage.getItem(key);
    const parsedResult = JSON.parse(result);
    const wallet = JSON.parse(parsedResult.wallet);
    return wallet.walletsByNetwork.cosmos;
  } catch (error) {
    console.log('error getWalletByNetworksFromStorage: ', error);
  }
};
