import useWindowSize from 'hooks/useWindowSize';
import React, { memo, useCallback, useEffect, useMemo } from 'react';
import Header from './Header';
import ConnectWallet from './Header/ConnectWallet';
import useTheme from 'hooks/useTheme';
import useConfigReducer from 'hooks/useConfigReducer';
import RenderWhen from 'components/RenderWhen';
import { ROUTES } from 'routes/constant';
import { setCurrentToken, setListToken, setListTokenFilterInitial } from 'redux/reducers/tradingSlice';
import { useDispatch } from 'react-redux';
// import TopRanking from './Header/TopRanking';
import { useLocation } from 'react-router-dom';
import SelectSwapTokenWrapper from './Header/SelectSwapToken';
import { BTC_CONTRACT, USDT_CONTRACT, XOCH_CONTRACT } from '@oraichain/oraidex-common';
import { PairToken } from 'redux/reducers/type';
import { DATA_PAIRS } from 'config/orderbook';

const IGNORE_HEADER_ROUTES = [ROUTES.RANKING];

const MainLayout: React.FC<{
  children: React.ReactNode | React.ReactNode[] | React.ReactElement | React.ReactElement[];
}> = ({ children }) => {
  const { isMobileView } = useWindowSize();
  const { theme } = useTheme();
  const [address] = useConfigReducer('address');
  const location = useLocation();
  const dispatch = useDispatch();

  const isShowHeader = useMemo(() => {
    return !IGNORE_HEADER_ROUTES.find((r) => r === location.pathname);
  }, [location.pathname]);

  const getPairs = useCallback(async () => {
    dispatch(setListToken(DATA_PAIRS));
    dispatch(setListTokenFilterInitial(DATA_PAIRS));
  }, [dispatch]);

  useEffect(() => {
    const current = DATA_PAIRS.find((pair) => location.pathname.includes(pair.route)) || DATA_PAIRS[0];
    dispatch(setCurrentToken(current));
  }, [location.pathname]);

  useEffect(() => {
    getPairs();
  }, []);

  return (
    <div>
      {isShowHeader ? (
        <>
          <Header />
          {/* <RenderWhen isTrue={isMobileView}>
            <RenderWhen.If isTrue={!!address}>
              <div
                style={{
                  padding: '10px 15px',
                  background: theme === 'dark' ? '#151619' : '#fcfcfc',
                  marginBottom: '8px'
                }}
              >
                <ConnectWallet hasButtonConnect={false} />
                <SelectSwapTokenWrapper />
              </div>
            </RenderWhen.If>
          </RenderWhen> */}
          {/* <RenderWhen isTrue={isMobileView}>
            <RenderWhen.If isTrue>
              <div
                style={{
                  padding: '10px 15px',
                  background: theme === 'dark' ? '#151619' : '#fcfcfc',
                  marginBottom: '8px',
                  display: 'flex',
                  flexWrap: 'wrap'
                }}
              >
                <TopRanking />
              </div>
            </RenderWhen.If>
          </RenderWhen> */}
        </>
      ) : null}

      {children}
    </div>
  );
};

export default memo(MainLayout);
