import { FC } from 'react';
import styles from './LoginWidget.module.scss';
import cn from 'classnames/bind';
import CenterEllipsis from 'components/CenterEllipsis';
import { ReactComponent as Logout } from 'assets/icons/logout.svg';
import useTheme from '../../hooks/useTheme';
import CopyCustom from 'components/CopyCustom';
import RenderWhen from 'components/RenderWhen';

const cx = cn.bind(styles);

export const LoginWidgetDeepLink: FC<{
  logo: string;
  text: string;
  address: string | null;
  disconnect: () => Promise<void>;
}> = ({ logo, text, address, disconnect }) => {
  const { theme } = useTheme();

  return (
    <div className={cx('item', theme)} onClick={address ? disconnect : () => {}}>
      <img src={logo} className={cx('logo')} alt="logo" />
      <div className={cx('grow')}>
        <RenderWhen>
          <RenderWhen.If isTrue={!!address}>
            <div className={cx('network-title')}>{text}</div>
            <div className={cx('des')}>
              <CenterEllipsis size={6} text={address} />
              <CopyCustom value={address} />
            </div>
          </RenderWhen.If>
          <RenderWhen.If isTrue={!address}>
            <div className={cx('network-title')}>{text}</div>
            <div className={cx('des')}>Connect using browser wallet</div>
          </RenderWhen.If>
        </RenderWhen>
      </div>
      <RenderWhen>
        <RenderWhen.If isTrue={!!address && !!disconnect}>
          <div>
            <Logout />
          </div>
        </RenderWhen.If>
      </RenderWhen>
    </div>
  );
};

export default LoginWidgetDeepLink;
