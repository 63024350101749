import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';
import { DECIMALS } from '../constants';
import { BuySellFilter, OrderDetailFromContract, PriceAmountFilled } from './type';

export interface OrderbookState {
  typeDecimal: string;
  typeFilter: BuySellFilter;
  isRefreshListOrder: boolean;
  isRefreshUserOrder: boolean;
  openOrders: OrderDetailFromContract[];
  priceAmountFilled: PriceAmountFilled;
  marketPrice: {
    buy: string;
    sell: string;
  };
}

const initialState: OrderbookState = {
  typeDecimal: DECIMALS[2].key,
  typeFilter: BuySellFilter.all,
  isRefreshListOrder: false,
  isRefreshUserOrder: false,
  openOrders: [],
  priceAmountFilled: {
    price: '',
    amount: ''
  },
  marketPrice: {
    buy: '',
    sell: ''
  }
};

export const orderbookSlice = createSlice({
  name: 'orderbook',
  initialState,
  reducers: {
    addTypeFilter: (state, action: PayloadAction<BuySellFilter>) => {
      state.typeFilter = action.payload;
    },
    addTypeDecimal: (state, action: PayloadAction<string>) => {
      state.typeDecimal = action.payload;
    },
    setIsRefreshListOrder: (state, action: PayloadAction<boolean>) => {
      state.isRefreshListOrder = action.payload;
    },
    setIsRefreshUserOrder: (state, action: PayloadAction<boolean>) => {
      state.isRefreshUserOrder = action.payload;
    },
    setOpenOrder: (state, action: PayloadAction<OrderDetailFromContract[]>) => {
      state.openOrders = action.payload;
    },
    addPriceAmountFilled: (state, action: PayloadAction<PriceAmountFilled>) => {
      state.priceAmountFilled = action.payload;
    },
    setMarketPrice: (state, action: PayloadAction<{ buy?: string; sell?: string }>) => {
      state.marketPrice = {
        ...state.marketPrice,
        ...action.payload
      };
    }
  }
});

export const {
  addTypeDecimal,
  addTypeFilter,
  setIsRefreshListOrder,
  setIsRefreshUserOrder,
  setOpenOrder,
  addPriceAmountFilled,
  setMarketPrice
} = orderbookSlice.actions;

export const selectTypeFilter = (state: RootState): BuySellFilter => state.orderbook.typeFilter;
export const selectTypeDecimal = (state: RootState): string => state.orderbook.typeDecimal;
export const selectIsRefreshListOrder = (state: RootState): boolean => state.orderbook.isRefreshListOrder;
export const selectIsRefreshUserOrder = (state: RootState): boolean => state.orderbook.isRefreshUserOrder;
export const selectMarketPrice = (state: RootState): { sell: string; buy: string } => state.orderbook.marketPrice;
export const selectOrders = (state: RootState): OrderDetailFromContract[] => state.orderbook.openOrders;
export const selectPriceAmountFilled = (state: RootState): PriceAmountFilled => state.orderbook.priceAmountFilled;

export default orderbookSlice.reducer;
