import { WALLET_ENUM } from 'components/ConnectWallet/constants';
import { displayToast, TToastType } from 'components/Toasts';
import { ThemeProvider } from 'context/theme-context';
import { displayInstallWallet } from 'helpers';
import { useClearStorage } from 'hooks/useClearStorage';
import useConfigReducer from 'hooks/useConfigReducer';
import useLoadTokens from 'hooks/useLoadTokens';
import { useInactiveConnect } from 'hooks/useMetamask';
import useWalletReducer from 'hooks/useWalletReducer';
import MainLayout from 'layouts';
import { useEffect } from 'react';
import { useMedia } from 'react-use';
import OraiRoutes from 'routes';
import GlobalStyles from 'styles/global';
import 'styles/global.scss';

function App() {
  const [walletAddress, setAddress] = useConfigReducer('address');
  const [, setStatusChangeAccount] = useConfigReducer('statusChangeAccount');
  const loadTokenAmounts = useLoadTokens();
  const isMobile = useMedia('(max-width: 991px)');
  const [walletByNetworks] = useWalletReducer('walletsByNetwork');

  // useTriggerMatchedOrderSocket();

  // add event change account
  useInactiveConnect();

  // clear storage
  useClearStorage();

  // auto connect only for mobile => so only need to check keplr
  const keplrHandler = async () => {
    if (!isMobile && !walletAddress) {
      return;
    }
    try {
      // automatically update. If user is also using Oraichain wallet => dont update
      const keplr = await window.Keplr.getKeplr();
      if (!keplr) {
        return displayInstallWallet();
      }

      const oraiAddress = await window.Keplr.getKeplrAddr();

      if (oraiAddress) {
        setAddress(oraiAddress);
      }

      // window.location.reload();
    } catch (error) {
      console.log('Error: ', error.message);
      setStatusChangeAccount(false);
      displayToast(TToastType.TX_INFO, {
        message: `There is an unexpected error with Keplr wallet. Please try again!`
      });
    }
  };

  useEffect(() => {
    keplrHandler();
  }, []);

  useEffect(() => {
    (async () => {
      if (walletByNetworks.cosmos !== WALLET_ENUM.EIP191 || isMobile) {
        window.addEventListener('keplr_keystorechange', keplrHandler);
      }
    })();
    return () => {
      window.removeEventListener('keplr_keystorechange', keplrHandler);
    };
  }, [walletByNetworks]);

  useEffect(() => {
    loadTokenAmounts(walletAddress);
  }, [walletAddress]);

  return (
    <ThemeProvider>
      <GlobalStyles />
      <MainLayout>
        <OraiRoutes />
      </MainLayout>
    </ThemeProvider>
  );
}

export default App;
