//@ts-nocheck
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import App from 'App';
import { ToastProvider } from 'components/Toasts/context';
import 'polyfill';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { Bounce, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from 'redux/store';
import reportWebVitals from './reportWebVitals';

import { CosmWasmClient } from '@cosmjs/cosmwasm-stargate';
import { network } from 'config/networks';
import { ROOT_ENV } from 'env';
import Keplr from 'libs/keplr';
import { getWalletByNetworkCosmosFromStorage } from 'libs/utils';
import { initClient } from 'polyfill';
const queryClient = new QueryClient();

// enable Keplr
const walletType = getWalletByNetworkCosmosFromStorage();
window.Keplr = new Keplr(walletType);

if (ROOT_ENV.REACT_APP_SENTRY_ENVIRONMENT === 'production') {
  Sentry.init({
    dsn: 'https://f1bf70cfe48e4b9c95769ba280e5e69f@o1323226.ingest.sentry.io/4505004695158784',
    integrations: [new BrowserTracing(), new Sentry.Replay()],
    environment: ROOT_ENV.REACT_APP_SENTRY_ENVIRONMENT,
    denyUrls: [
      /extensions\//i,
      /extension/i,
      /vendor/i,
      /^chrome:\/\//i,
      /^chrome-extension:\/\//i,
      /^moz-extension:\/\//i
    ],
    ignoreErrors: [
      'Request rejected',
      'Failed to fetch',
      'Load failed',
      'User rejected the request',
      "Failed to execute 'insertBefore' on 'Node'",
      'Non-Error promise rejection captured'
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

const initApp = async () => {
  window.client = await CosmWasmClient.connect(network.rpc);

  const root = createRoot(document.getElementById('root'));
  root.render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ToastProvider>
          <BrowserRouter>
            <QueryClientProvider client={queryClient}>
              <App />
            </QueryClientProvider>
          </BrowserRouter>
          <ToastContainer transition={Bounce} />
        </ToastProvider>
      </PersistGate>
    </Provider>
  );

  await initClient();
};

initApp();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
