import { combineReducers } from '@reduxjs/toolkit';
import configReducer from './configSlice';
import orderbookReducer from './orderbookSlice';
import actionsReducer from './actionSlice';
import tokenReducer from './tokenSlice';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { PERSIST_CONFIG_KEY } from 'redux/constants';
import tradingReducer from './tradingSlice';
import walletReducer from './wallet';

const rootPersistConfig = {
  key: PERSIST_CONFIG_KEY,
  storage
};

const storageReducer = combineReducers({
  config: configReducer,
  token: tokenReducer,
  wallet: walletReducer
});

const persistedReducer = persistReducer(rootPersistConfig, storageReducer);

const rootReducer = combineReducers({
  actions: actionsReducer,
  orderbook: orderbookReducer,
  trading: tradingReducer,
  persistStorage: persistedReducer
});

export default rootReducer;
